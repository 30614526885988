<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import CustomCardTitle from "@/components/custom-card/custom-card-title.vue";
import VLazyImage from "v-lazy-image/v2";

import NewsRender from "@/components/news/news-render.vue";
import OrdersTable from "../../../components/shop/orders-table.vue";

/**
 * Home Component
 */
export default {

  computed: {
    paginationHelper() {
      return paginationHelper
    },

    dateUtil() {
      return dateUtil
    },

    userName() {
      return (userId) => {
        const user = this.getUser(userId)
        if (!user) {
          return ""
        }

        return user.username
      };
    },

    userAvatar() {
      return (userId) => {
        const user = this.getUser(userId)
        if (!user) {
          return ""
        }

        return user.profilePictureUrl
      };
    },

    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  components: {
    OrdersTable,
    NewsRender,

    CustomCardTitle,
    Layout,
    PageHeader,
    VLazyImage
  },

  data() {
    return {
      latestNews: null,
      newsUser: null,

      shopSalesRanking: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "turnover",
        sortDesc: true,

        items: [],
        orderByMap: new Map([
          ["turnover", "sr.turnover"],
          ["profit", "sr.profit"],
          ["returnOfInvestment", "sr.return_of_investment"],
        ])
      },

      userSalesRanking: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "turnover",
        sortDesc: true,

        items: [],

        orderByMap: new Map([
          ["turnover", "ur.turnover"],
          ["profit", "ur.profit"],
          ["returnOfInvestment", "ur.return_of_investment"],
        ])
      },

      users: null
    };
  },

  methods: {

    setToFirstPageAndRefresh(table, refName) {
      this.paginationHelper.setToFirstPageAndRefresh(this, table, refName)
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('menuitems.dashboard.text'),
          active: true
        }
      ]
    },

    async loadNews() {
      try {
        const {data} = await axios.get(`/news/list-enabled`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.latestNews = data[0]
        await this.loadNewsUsers()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadNewsUsers() {
      try {
        if (!this.latestNews) {
          return;
        }

        const json = JSON.stringify({
          "ids": [this.latestNews.author]
        });

        const {data} = await axios.post(`/user`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.newsUser = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getShopRankingFields() {
      return [
        {key: "shop", slot: true, label: this.$t('table.shop-name')},
        {
          key: "turnover", sortable: true, label: this.$t("ranking-table.turnover"), formatter: (value) => {
            if (!value) {
              return '0.0'
            }

            return value.toFixed(2)
          }
        },
        {
          key: "profit", sortable: true, label: this.$t("ranking-table.profit"), formatter: (value) => {
            if (!value) {
              return '0.0'
            }

            return value.toFixed(2)
          }
        },
        {
          key: "returnOfInvestment", sortable: true, label: this.$t("ranking-table.return-of-investment"), formatter: (value) => {
            if (!value) {
              return '0.0'
            }

            return value.toFixed(2) + "%"
          }
        },
      ]
    },

    getUserRankingFields() {
      return [
        {key: "user", slot: true, label: this.$t("ranking-table.user")} ,
        {
          key: "turnover", sortable: true, label: this.$t("ranking-table.turnover"), formatter: (value) => {
            if (!value) {
              return '0.0'
            }

            return value.toFixed(2)
          }
        },
        {
          key: "profit", sortable: true, label: this.$t("ranking-table.profit"), formatter: (value) => {
            if (!value) {
              return '0.0'
            }

            return value.toFixed(2)
          }
        },
        {
          key: "returnOfInvestment", sortable: true, label: this.$t("ranking-table.return-of-investment"), formatter: (value) => {
            if (!value) {
              return '0.0'
            }

            return value.toFixed(2) + "%"
          }
        },
      ]
    },

    async loadUsersRanking() {
      let page = this.userSalesRanking.currentPage - 1;
      if (page > 0) {
        page = this.userSalesRanking.currentPage * this.userSalesRanking.perPage - this.userSalesRanking.perPage;
      }

      let orderBy = {}
      if (this.userSalesRanking.sortBy) {
        const orderByElement = this.userSalesRanking.orderByMap.get(this.userSalesRanking.sortBy)
        if (orderByElement) {
          orderBy = JSON.stringify({
            [orderByElement]: this.userSalesRanking.sortDesc ? "DESC" : "ASC"
          })
        }
      }

      const result = await axios.get(`/user/ranking/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.userSalesRanking.perPage,
          filter: this.userSalesRanking.filter,
          orderBy: orderBy
        }
      });

      const data = result.data

      this.userSalesRanking.items = data.userRankings
      this.userSalesRanking.totalRows = data.count;
      this.userSalesRanking.rows = data.count;

      await this.loadUsers()
      return this.userSalesRanking.items;
    },

    async loadShopsRanking() {
      let page = this.shopSalesRanking.currentPage - 1;
      if (page > 0) {
        page = this.shopSalesRanking.currentPage * this.shopSalesRanking.perPage - this.shopSalesRanking.perPage;
      }

      let orderBy = {}
      if (this.shopSalesRanking.sortBy) {
        const orderByElement = this.shopSalesRanking.orderByMap.get(this.shopSalesRanking.sortBy)
        if (orderByElement) {
          orderBy = JSON.stringify({
            [orderByElement]: this.shopSalesRanking.sortDesc ? "DESC" : "ASC"
          })
        }
      }

      const {data} = await axios.get(`/shop/ranking/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          page: page,
          size: this.shopSalesRanking.perPage,
          filter: this.shopSalesRanking.filter,
          orderBy: orderBy
        }
      });

      this.shopSalesRanking.items = data.shopRankings
      this.shopSalesRanking.totalRows = data.count;
      this.shopSalesRanking.rows = data.count;

      return this.shopSalesRanking.items;
    },

    async loadUsers() {
      if (this.userSalesRanking.items === 0) {
        return;
      }

      const ids = [];
      for (let userRanking of this.userSalesRanking.items) {
        ids.push(userRanking.userId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    getUser(id) {
      if (!this.users) {
        return null
      }

      return this.users.get(id) || null
    }
  },

  async created() {
    try {
      await this.loadNews()
      await this.loadUsersRanking()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('menuitems.dashboard.text')"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <custom-card-title :title="$t('dashboard.orders.title')"/>
          <div class="card-body">
            <orders-table :for-user-only="true" :dashboard="true" />
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-12">
        <div class="card">
          <custom-card-title :title="$t('dashboard.shops-sales-ranking.title')"/>
          <div class="card-body">
            <ecat-table
                ref="shopSalesRankingTable"
                :table="shopSalesRanking"
                :fields="getShopRankingFields()"
                :items="loadShopsRanking"
                :pagination-top="true"
                >
              <template v-slot:shop="{ item }">
                <a v-if="item.shopDomain" :href="`//${item.shopDomain}`" target="_blank">{{ item.shopDomain }}</a>
                <a v-else>{{ item.shopName }}</a>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-12">
        <div class="card">
          <custom-card-title :title="$t('dashboard.users-sales-ranking.title')"/>
          <div class="card-body">
            <ecat-table
                ref="usersSalesRankingTable"
                :table="userSalesRanking"
                :fields="getUserRankingFields()"
                :items="loadUsersRanking"
                :pagination-top="true"
            >
              <template v-slot:user="{ item }">
                      <span>
                        <v-lazy-image
                            :src="userAvatar(item.userId)"
                            src-placeholder="https://i.imgur.com/OI4ACNk.gif"
                            alt="User Avatar"
                            class="rounded-circle header-profile-user mr-1"
                        />

                        {{ userName(item.userId) }}
                      </span>

              </template>
            </ecat-table>
          </div>
        </div>
      </div>

      <news-render v-if="latestNews && newsUser" :news="latestNews" :news-user="newsUser" :isFirstNews="false"/>
    </div>

  </Layout>
</template>